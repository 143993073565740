<template lang="pug">
q-select.BaseSelect(
  @input="$emit('input', $event)"
  v-bind="$attrs" 
  outlined popup-content-class="baseSelect-popup")
</template>

<script>
export default {
}

</script>

<style lang="scss">
// TODO закончить рефакторинг компонента
.BaseSelect {
  .q-field__control {
    border-radius: 15px;
  }
}
</style>