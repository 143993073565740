<template lang="pug">
.discountSelects
  MainSelect(
    v-if="laboratory"
    :value="laboratory"
    :options="labsOptions"
    optionLabel="title"
    @input-select="$emit('update:laboratory', $event)").discountSelect.discountDays-select
  MainSelect(
    :value="city"
    :options="cityOptions"
    optionLabel="title"
    @input-select="$emit('update:city', $event)").discountSelect.discountDays-select
  //- BaseSelect(v-model="laboratory" :options="labsOptions" option-label="title")
</template>

<script>
import MainSelect from '@/components/UI/MainSelect.vue';
import BaseSelect from '@/components/UI/BaseSelect.vue';

export default {
  components: {MainSelect, BaseSelect},
  props: {
    city: Object,
    laboratory: Object,
    labsOptions: Array,
    cityOptions: Array,
  }
}
</script>

<style lang="scss">

.discountSelect {
  .q-field__control {
    background: white !important;
  }
}

.discountSelects {
  grid-row: 2;
  grid-column: span 1;
  @include media-down($breakpoint-md) {
    grid-row: auto;
    grid-column: auto;
  }
}

.discountSelects {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @include media-down($breakpoint-md) {
    grid-template-columns: 1fr;
  }
  &._rightAbsolute{
    @include media-up($breakpoint-md) {
      position: absolute;
      right: 15px;
      display: block;
      width:325px
    }
  }
}

.discountSelects {
  margin-bottom: 30px;
  @include media-down($breakpoint-md) {
    gap: 12px
  }
}
</style>