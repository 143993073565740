<template lang="pug">
.personalDiscount
  .personalDiscount-icons
    icon(name="ls-logo" width="21px" height="17px")
    //- icon(name="panorama-logo" width="26px" height="26px")
  .personalDiscount-content
    .personalDiscount-text Ваша персональная скидка <br>в ЛабСтори
    .personalDiscount-about(@click="isModalActive = true") Подробнее
  .personalDiscount-amount {{currentDiscount}}%
  MessageModal(v-model="isModalActive" title="Ваша персональная скидка" hideOkButton size="sm")
    template(#body)
      span В центрах ЛабСтори и ПанорамаМед вы можете получить скидку при оформлении заказа в центре.
      .personalDiscount-items
        .personalDiscount-item(v-for="item in personalDiscounts")
          .personalDiscount-logo: icon(name="ls-logo" width="21px" height="17px")
          .personalDiscount-text {{item.clinic}}, {{item.city}}
          .personalDiscount-discount {{item.discount}}%
      span Она автоматически применится при оформлении ко всей сумме заказа. Скидка не распространяется на взятие биоматериала, на генетические и аутоиммунные исследования, на гинекологические и урологические исследования. С другими акциями и специальными предложениями не суммируется.
      span Спасибо, что остаетесь нашим пациентом 😊
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import { getPersonalDiscounts } from '@/api/discounts.js';

export default {
  data(){
    return {
      isModalActive: false,
      personalDiscounts: []
    }
  },
  components: {MessageModal},
  computed: {
    currentDiscount(){
      const {current_discount_city_id, spb_discount, regions_discount} = this.$store.state.personalArea.patient
      const spbCondition = (current_discount_city_id === 1 || current_discount_city_id === null)
      return (spbCondition ? spb_discount : regions_discount) || 0
    }
  },
  async mounted(){
    this.personalDiscounts = await getPersonalDiscounts()
  }
}
</script>

<style lang="scss">
.personalDiscount{
  display: flex;
  gap: 16px;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 12px 12px 12px 16px;
}

.personalDiscount-items {
  display: flex;
  flex-direction: column;
  background: $light-background;
  border-radius: 14px;
  padding: 12px;
  width: 100%;
  gap: 10px  
}
.personalDiscount-item {
  display: flex;
  width: 100%;
  gap: 16px;
  align-items: center;
}

.personalDiscount-discount {
  margin-left: auto;
  font-weight: 500;
  color: $accent-color;
}

.personalDiscount-icons {
  display: flex;
  align-items: center;
  height: fit-content;
  gap: 7px;
  & > * {
    flex-shrink: 0;
  }
}

.personalDiscount-content {
  width: 192px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.personalDiscount-text{
  font-size: 12px;
  line-height: 15px;
  color: $grey-2;
  font-weight: 400;
}
.personalDiscount-about{
  font-size: 12px;
  line-height: 17px;
  color: $grey-2;
  font-weight: 500;
  cursor: pointer;
}

.personalDiscount-amount {
  background: $light-background;
  border-radius: 14px;
  font-size: 14px;
  line-height: 18px;
  color: #7C74E9;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 51px;
  padding: 12px 16px;
  @include media-down($breakpoint-md) {
    margin-left: auto;
  }
}

.personalDiscount-logo {
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>