<template lang="pug">
.discountGroupHeader
  img(:src="partner_logo" :alt="partner_title").discountGroupHeader-logo
  .discountGroupHeader-amount {{amount}} {{pluralizeText(amount, $options.units[type])}}
</template>

<script>
import { pluralizeText } from '@/utils/utils';

const units = {
  promocodes: ['промокод', 'промокода', 'промокодов'],
  offers: ['предложение', 'предложения', 'предложений'],
}

export default {
  methods: {
    pluralizeText,
  },
  units,
  props: {
    amount: Number,
    type: String,
    partner_id: Number,
    partner_title: String,
    partner_logo: String,
  }
}
</script>

<style lang="scss">

.discountGroupHeader {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 22px;
  @include media-down($breakpoint-md) {
    justify-content: space-between;
  }
}

.discountGroupHeader-amount {
  border: 1px solid $light-stroke;
  border-radius: 12px;
  padding: 10px 22px;
  font-size: $grey-2;
  @include media-down($breakpoint-md) {
    font-size: 12px;
    line-height: 15px;
  }
}



.discountGroupHeader-logo {
  width: 152px;
  height: 50px;
  border-radius: 15px;
}

</style>