<template lang="pug">
.discountDay
  .discountDay-location
    icon(name="location").discountDay-icon
    .discountDay-address {{address}}
    .discountDay-subway {{metro}}
  DiscountCalender(:days="discount_days").discountDay-calender
  a.discountDay-button(:href="clinic_link" target="_blank") Подробнее про центр
</template>

<script>
import DiscountCalender from "./DiscountCalender.vue";
export default {
  components: { DiscountCalender },
  props: {
    address: String,
    clinic_link: String,
    metro: String,
    discount_days: Array,
  }
}
</script>

<style lang="scss">
.discountDay {
  padding: 16px;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  transition: .5s;
  &:hover {
    box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  }
}

.discountDay-calender {
  margin-top: auto;
}

.discountDay-location {
  display: grid;
  grid-template-columns: min-content auto;
  gap: 4px 10px;
}

.discountDay-icon {
  grid-row: 1/3;
}

.discountDay-address {
  color: $grey-1;
  font-size: 14px;
  line-height: 18px;
}



.discountDay-subway {
  color: $grey-3;
  font-size: 12px;
  line-height: 15px;
} 

.discountDay-button {
  background: #F9F9FC;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $light-background;
  width: 100%;
  height: 37px;
  font-size: 12px;
  line-height: 17px;
  cursor: pointer;
  text-decoration: none;
  color: $grey-2;
  margin-top: auto;
}
</style>