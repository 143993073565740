<template lang="pug">
.discountItems
  DiscountGroupHeader(:amount="coupons.length" v-bind="$props" type="promocodes")
  .discountItems-grid
    DiscountItem(v-for="coupon in coupons" v-bind="coupon")
</template>

<script>
import DiscountItem from './DiscountItem.vue';
import DiscountGroupHeader from './DiscountGroupHeader.vue';

export default {
  components: {
    DiscountItem,
    DiscountGroupHeader,
  },
  props: {
    partner_id: Number,
    partner_logo: String,
    partner_title: String,
    coupons: Array,
  },
}
</script>

<style lang="scss">
.discountItems {

  & + & {
    margin-top: 50px;
  }
}

.discountItems-grid {
  display: grid;
  overflow: visible;
  grid-template-columns: 1fr 1fr 1fr;
  overflow: scroll;
  grid-gap: 30px;
  @include media-down($breakpoint-md) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
}
</style>