<template lang="pug">
.discountDays
  .discountDays-grid(:style="{marginBottom: '16px'}"): .discountDays-decriprtion В каждом нашем центре есть скидочные дни — вы сможете <span class="text-primary">получить скидку 15%</span> на лабораторные исследования при оформлении заказа в центре
  DiscountFilters(
    :city.sync="city" 
    :laboratory.sync="laboratory" 
    :labsOptions="labsOptions" 
    :cityOptions="cityOptions")
  .h3.discountDays-title Список центров
  .discountDays-grid
    DiscountDay(v-for="day in filteredDays" v-bind="day")
    span(v-if="filteredDays.length === 0") Нет подходящих центров
</template>

<script>
import DiscountDay from './DiscountDay.vue';
import DiscountFilters from './DiscountFilters.vue';
import MainSelect from '@/components/UI/MainSelect.vue';
import { uniq } from 'lodash';

export default {
  data(){ 
    return {
      laboratory: null,
      city: null
    }
  },
  props: {
    days: Array
  },
  components: {
    DiscountDay,
    MainSelect,
    DiscountFilters
  },

  computed: {
    labsOptions() {
      const allLabs = this.days.map( day => day.clinic)
      const allUniqLabs = uniq(allLabs)
      const options = allUniqLabs.map ( lab => {
        return {value: lab, title: lab}
      })
      return [{value: null, title: 'Все лаборатории'}, ...options]
    },
    cityOptions() {
      const allCity = this.days.map( day => day.city)
      const allUniqCity = uniq(allCity)
      const options = allUniqCity.map ( city => {
        return {value: city, title: city}
      })
      return [{value: null, title: 'Все города'}, ...options]
    },
    filteredDays() {
      return this.days
        .filter( day => day.clinic === this.laboratory?.value || this.laboratory?.value === null)
        .filter( day => day.city === this.city?.value || this.city?.value === null)
    },
  },

  watch: {
    laboratory(){
      if (this.laboratory.value === 'Панорама Мед') {
        this.city = {value: null, title: 'Все города'}
      }
    }
  },

  mounted(){
    this.laboratory = this.labsOptions[0]
    this.city = this.cityOptions[0]
  },
}
</script>

<style lang="scss">
.discountDays {
}

.discountDays-title {
  margin-bottom: 16px;
}

.discountDays-decriprtion {
  font-size: 14px;
  line-height: 20px;  
  color: $grey-2;
  margin-bottom: 16px;
  grid-column: span 2;
  @include media-down($breakpoint-md) {
    grid-column: auto;
    font-size: 16px;
    line-height: 22px;
  }
}

.discountDays-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  @include media-down($breakpoint-md) {
    grid-template-columns: 1fr;
  }
}


</style>