<template lang="pug">
a(:href="link" target="_blank").discountOffer(v-if="is_published")
  img(:src="image_url").discountOffer-img
  .discountOffer-btn Подробнее
</template>

<script>

export default {
  props: {
    cities: Array,
    id: Number,
    image_url: String,
    is_published: Boolean,
    link: String,
    partner: Number
  }
}

</script>

<style lang="scss">
.discountOffer {
  height: 302px;
  border: 1px solid $light-stroke;
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  transition: .3s;
  background: white;
  // &:hover {
  //   height: 260px;
  // }
}

.discountOffer-img {
  width: 100%;
  object-fit: cover;
  border-radius: 30px;
  height: 260px;
  transition: .3s;
  &:hover {
    height: 302px;
  }
}

.discountOffer-btn {
  width: calc(100% - 40px);
  border-radius: 14px;
  background: white;
  height: 40px;
  font-size: 12px;
  line-height: 17px;
  color: $grey-1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: .3s;
  .discountOffer:hover & {
    bottom: 10px;
  }
}

</style>