<template lang="pug">
.discountCalender
  .discountCalender-theDay(:class="isActive('понедельник')") Пн
  .discountCalender-theDay(:class="isActive('вторник')") Вт
  .discountCalender-theDay(:class="isActive('среда')") Ср
  .discountCalender-theDay(:class="isActive('четверг')") Чт
  .discountCalender-theDay(:class="isActive('пятница')") Пт
  .discountCalender-theDay._st(:class="isActive('суббота')") Сб
  .discountCalender-theDay(:class="isActive('воскресенье')") Вс
</template>

<script>
export default {
  props: {
    days: Array
  },
  methods: {
    isActive(day){
      if (this.days.includes(day)) {
        return {_active: true}
      }
    }
  }
}
</script>

<style lang="scss">
.discountCalender {
  display: flex;
  gap: 4px;
}

.discountCalender-theDay  {
  width: 32px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $grey-6;
  color: $grey-4;
  font-weight: 500;
  border-radius: 10px;
  cursor: default;
  font-size: 12px;
  &._active {
    border-color: $accent-color;
    background: $accent-color;
    color: white
  }
  &._st {
    margin-left: 10px;
  }
  @include media-down($breakpoint-md) {
    width: 100%;
  }
}
</style>