<template lang="pug">
.discountOffers
  DiscountGroupHeader(:amount="partner_offers.length" type="offers" v-bind="$props")
  .discountOffers-grid
    DiscountOffer(v-for="offer in partner_offers" v-bind="offer")
</template>

<script>
import DiscountOffer from './DiscountOffer.vue';
import DiscountGroupHeader from './DiscountGroupHeader.vue';

export default {
  props: {
    partner_id: Number,
    partner_logo: String,
    partner_title: String,
    partner_offers: Array,
  },
  components: {
    DiscountOffer,
    DiscountGroupHeader
  },
}
</script>

<style lang="scss">
.discountOffers {
  & + & {
    margin-top: 70px;
  }

}
.discountOffers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 300px;
  gap: 20px;
  @include media-down($breakpoint-md) {
    grid-template-columns: 1fr;
  }
}
</style>