<template lang="pug">
.discountPage.layout
  BackBtn.back-btn.mobile(@go-back="$router.go(-1)" :style="{marginTop: '-40px'}")
  h4.h1.discountPage-title Витрина скидок
    PersonalDiscount.discountPage-personalDiscount
  BaseTabs.discountPage-tabs(:data="tabsData" @tabChange="activeTab = $event" :activeTab="activeTab")
    q-tab-panel(name="offers")
      .discountDays-grid(:style="{marginBottom: '16px'}"): .discountDays-decriprtion Здесь собраны все предложения от наших партнеров
      DiscountFilters(
        :city.sync="filter.cityOffers" 
        :cityOptions="couponCitiesOptions")._rightAbsolute
      span(v-if="loading") Загрузка...
      template(v-else): DiscountOffers(v-for="partner in partnersWithOffers" v-bind="partner")
    q-tab-panel(name="discounts")
      DiscountFilters(
        :city.sync="filter.city" 
        :laboratory.sync="filter.laboratory" 
        :labsOptions="couponPartnersOptions" 
        :cityOptions="couponCitiesOptions")
      span(v-if="loading") Загрузка...
      template(v-else): DiscountItems(v-for="partner in partnersWithCoupons" v-bind="partner")
    q-tab-panel(name="discountDays")
      DiscountDays(:days="discountDays")
</template>

<script>
import BaseTabs from '@/components/BaseTabs.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';

import DiscountDays from './DiscountDays.vue';
import DiscountOffers from './DiscountOffers.vue';
import DiscountItems from './DiscountItems.vue';
import PersonalDiscount from './PersonalDiscount.vue';
import DiscountFilters from './DiscountFilters.vue';

export default {
  components: {
    BaseTabs,
    DiscountItems,
    DiscountOffers,
    DiscountDays,
    PersonalDiscount,
    DiscountFilters,
    BackBtn,
  },
  data(){
    return {
      activeTab: 'offers',
      partnersWithCoupons: [],
      partnersWithOffers: [],
      discountDays: [],
      couponCitiesOptions: [],
      couponPartnersOptions: [],
      filter: {
        city: {title: 'Все города', id: null},
        cityOffers: {title: 'Все города', id: null},
        laboratory: {title: 'Все партнеры', id: null},
      },
      tabsData: [],
      loading: false,
      
    }
  },
  async mounted() {
    const [ 
      partnersWithCoupons, 
      partnersWithOffers, 
      discountDays, 
      couponCities,
      couponPartners] = await Promise.all([
      this.$api.discounts.getCoupons(),
      this.$api.discounts.getPartnerOffers(),
      this.$api.discounts.getDiscountDays(),
      this.$api.discounts.getCouponCities(),
      this.$api.discounts.getCouponPartners()
    ])

    this.partnersWithCoupons = partnersWithCoupons
    this.partnersWithOffers = partnersWithOffers
    this.discountDays = discountDays
    this.couponCitiesOptions = [this.filter.city, ...couponCities]
    this.couponPartnersOptions = [this.filter.laboratory, ...couponPartners]
    this.tabsData = [
      {
        name: 'offers',
        label: 'Предложения',
        component: DiscountOffers,
        badge: this.allAmountOf(this.partnersWithOffers, 'partner_offers')
      },
      {
        name: 'discounts',
        label: 'Скидки',
        component: DiscountItems,
        badge: this.allAmountOf(this.partnersWithCoupons, 'coupons')
      },
      {
        name: 'discountDays',
        label: 'Скидочные дни',
        component: DiscountDays,
        badge: this.discountDays?.length
      },
    ]
  },
  watch: {
    'filter.city'() {
      this.reloadCoupons()
    },
    'filter.laboratory'() {
      this.reloadCoupons()
    },
    'filter.cityOffers'() {
      this.reloadOffers()
    },
  },
  methods: {
    async reloadCoupons(){
      this.loading = true
      this.partnersWithCoupons = await this.$api.discounts.getCoupons({city_id: this.filter.city?.id, partner_id: this.filter.laboratory?.id})
      this.loading = false
    },
    async reloadOffers(){
      this.loading = true
      this.partnersWithOffers = await this.$api.discounts.getPartnerOffers({city_id: this.filter.cityOffers?.id})
      this.loading = false
    },
    allAmountOf(partners, target) {
      return partners?.flatMap( partner => partner[target]).length
    }
  },

}
</script>

<style lang="scss">
.discountPage {
}

.discountPage-tabs  {


  .q-tab {
    &:not(.q-tab--active) {
      background: $light-background;
    }
    width: 230px;
    @include media-down($breakpoint-md) {
      width: auto;
      box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
      &.q-tab--inactive {
        background: white;
      }
    }
  }
  .q-tab__label {
    @include media-down($breakpoint-md) {
      font-size: 14px;
    }
  }
  .tabs__badge {
    width: 23px;
    transform: translate(100px, -50%);
    @include media-down($breakpoint-md) {
      transform: translate(0, -50%);
      right: -25px;
      top: -3px;
    }
  }
  .tabs__header {
    border: none;
    @include media-up($breakpoint-md) {
      background: white;
    }
    @include media-down($breakpoint-md) {
      padding-top: 10px;
      margin-bottom: 0px;
      padding-bottom: 24px;
      padding-right: 20px;
      padding-left: 20px;
      position: relative;
      right: 20px;
      width: calc(100% + 40px);
      overflow-x: scroll;
      overflow-y: visible;
      border-radius: 0;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .q-tabs__content {
      @include media-down($breakpoint-md) {
        gap: 6px
      }

    }
  }

}

.discountPage-title {
  margin-bottom: 30px;
  position: relative;
  @include media-down($breakpoint-md) {
    margin-bottom: 125px;
  }
}

.discountPage-personalDiscount {
  position: absolute;
  right: 0;
  @include media-down($breakpoint-md) {
    margin-top: 30px;
    left: 0;
  }
}
</style>