<template lang="pug">
.discountItem(:class="{_disabled: disabled}" @click="isModalActive = true")
  .discountItem-photoBox(:style="imageStyle")
    .discountItem-zoomBtn: icon(name="zoom")
    .discountItem-discountValue {{discount_value}}{{discountType}}
  .discountItem-content
    .discountItem-amount {{expiresTime}}
    .discountItem-title {{title}}
    PromoCode.discountItem-codeInput(:code="code")
  MessageModal(size="xs" v-model="isModalActive" rowButtons wideButtons)
    template(#body)
      .discountItem-modalContent
        .discountItem-modalCard
          .discountItem-photoBox(:style="imageStyle")._modal
            .discountItem-discountValue {{discount_value}}{{discountType}}
          .discountItem-content
            .discountItem-amount {{expiresTime}}
            .discountItem-title {{title}}
            .discountItem-info {{info}}
        .discountItem-tip
          icon(name="doc-progress-icon").discountItem-tipIcon
          .discountItem-tipText Промокоды не суммируются с вашей персональной скидкой. Акция не распространяется на некоторые виды услуг
          //- .discountItem-tipAbout Подробнее о правилах акции
        .discountItem-alert Покажите это окно администратору, если вы находитесь в одном из наших центров ✌
        //- .discountItem-usePromocodeText Или воспользуйтесь промокодом онлайн
      PromoCode(:code="code").discountItem-modalCodeInput
      .discountItem-subtext {{description}}
    template(#buttons)
      BaseBtn.discountItem-link(v-for="link in links" :href="link.url" size="sm") {{link.text}}
</template>

<script>
// TODO refactor name item = coupon
import PromoCode from './PromoCode.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';

export default {
  data(){
    return {
      isModalActive: false
    }
  },
  components: {
    PromoCode,
    MessageModal
  },
  props: {
    disabled: {type: Boolean},
    cities: Array,
    code: String,
    description: String,
    discount_type: String,
    discount_value: Number,
    expires_in: String,
    id: Number,
    image_url: String,
    info: String,
    is_unlimited_use: Boolean,
    links: Array,
    services: Array,
    title: String,
    use_count: Number
  },
  computed: {
    imageStyle() {
      return {backgroundImage: `url(${this.image_url})`}
    },
    discountType(){
      const discountTypes = {
        amount: 'Р',
        percent: '%'
      }
      return discountTypes[this.discount_type]
    },
    expiresTime(){
      if (this.is_unlimited_use) {
        return 'Безлимит'
      } 
      else {
        return `До ${this.$date(new Date(this.expires_in), 'dd MMMM yyyy')}`
      }
    }
  }
}
</script>

<style lang="scss">
.discountItem {
  box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  border-radius: 30px;
  position: relative;
  display: flex;
  gap: 20px;
  padding: 6px;
  padding-right: 20px;
  background: white;
  cursor: pointer;
  transition: .2s;
  &:hover {
    box-shadow: none;
  }
}

.discountItem-content {
  display: flex;
  flex-direction: column;
  width: 100%;

}

.discountItem-amount {
  color: $accent-color;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  margin-top: 10px;
  .discountItem._disabled & {
    opacity: .5;
  }
}

.discountItem-title {
  font-size: 14px;
  line-height: 18px;
  color: $grey-1;
  padding-top: 4px;
  font-weight: 500;
  .discountItem._disabled & {
    opacity: .5;
  }
}

.discountItem-photoBox {
  width: 164px;
  height: 164px;
  border-radius: 24px;
  // background-image: url('../../assets/iherb.jpg');
  background-size: cover;
  flex-shrink: 0;
  position: relative;
  transition: .2s;
  .discountItem._disabled & {
    opacity: .5;
  }
  .discountItem:hover & {
    opacity: 0.85;
  }
  @include media-down($breakpoint-md) {
    &._modal {
      width: 110px;
      height: 110px;
    }
  }
}

.discountItem-zoomBtn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 10px;
  right: 10px;
  border-radius: 100px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  .discountItem._disabled & {
    display: none;
  }
  .discountItem:hover & {
    transform: scale(1.2)
  }
}

.discountItem-discountValue {
  width: 48px;
  height: 48px;
  background: $accent-color;
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
  position: absolute;
  bottom: 12px;
  left: 12px;
  .discountItem._disabled & {
    opacity: .5;
  }
}

.discountItem-codeInput {
  margin-top: auto;
  margin-bottom: 12px;
  width: 100%;

}


.discountItem-link {
  flex-grow: 1;
  flex-shrink: 0 !important;
}

.discountItem-info {
  margin-top: 12px;
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
}


.discountItem-modalCard {
  display:flex;
  gap: 20px
}

.discountItem-modalContent {
  text-align: left;
  
}



.discountItem-tip {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 4px 10px;
  margin-top: 20px;
}

.discountItem-tipText {
  font-size: 12px;
  line-height: 15px;
  color: $grey-2;
}

.discountItem-tipAbout {
  grid-column: 2;
  font-size: 12px;
  line-height: 17px;
  color: $grey-4;
}

.discountItem-tipIcon {
  path:nth-child(1) {
    fill: $accent-color
  }
}

.discountItem-alert {
  background: $light-background;
  border-radius: 15px;
  padding: 12px;

  font-size: 14px;
  line-height: 20px;
  color: $grey-2;
  margin-top: 16px;
}

.discountItem-usePromocodeText {
  font-size: 12px;
  line-height: 15px;
  color: $grey-1;
  margin-top: 10px;
}

.discountItem-modalCodeInput {
  width: 100%;
}

.discountItem-subtext {
  line-height: 20px;
  text-align: center;
  color: #2D2D2D;
  margin-bottom: 4px;
}
.discountItem-modal {
  padding: 16px;
}
</style>