<template lang="pug">
.promoCode(@click.stop="copyToBuffer")
  span {{code}}
  .promoCode-copy.cursor-area: icon(name="copy")
</template>

<script>
export default {
  props: {
    code: {type: String}
  },
  methods: {
    copyToBuffer(){
      navigator.clipboard.writeText(this.code);
      this.$q.notify({
        color: 'primary',
        message: 'Промокод скопирован',
        classes: 'promoCode-notify',
        timeout: 3000
      });
    }
  }
}
</script>

<style lang="scss">
.promoCode {
  padding: 11px 10px 11px 14px;
  border: 1px solid $light-stroke;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: .5s;
  cursor: pointer;
  &:hover {
    border-color: $light-background;
    background: $light-background;
    color: $grey-3;
  }
}

.promoCode-notify {
  border-radius: 15px;
  min-height: 52px;
  .q-notification__message {
    font-size: 14px;
  }
}
</style>